import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import Section from "../components/section";
import SEO from "../components/seo"
import HeroXL from "../components/heroXL";
import TabBlock from "../components/tabBlock";
import { Container, Box, useTheme, Theme, Typography, Grid, Button, ButtonGroup, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import styled from "@emotion/styled";
import UserPath from "../components/userPath";
import ContentBlock from "../components/contentBlock";
import EventSlider from "../components/eventSlider";
import TestimonialSlider from "../components/testimonialSlider";
import { RoundPaper } from "../components/styledComponents";
import BlogTeaser from "../components/blog-teaser";
import { ShapeArea, ShapeDown, ShapeStack } from '../components/shapes';
import MainLayout from "../layouts/mainLayout";
import Hero from "../components/hero";
import LinkButton from "../components/link-button";
import BookBlock from "../composed/book-block";
import WhoBlock from "../composed/who-block";
import Subscribe from "../components/subscribe";

interface LocationProps {
  location: Location;
}

const LogoDiv = styled.div((props: { theme: Theme }) => `
  padding: 32px 120px;

  ${props.theme.breakpoints.down("sm")} {
    padding: 32px 0px;
  }
`);

const LogoTitle = styled(Typography)`
  padding-bottom: 16px;
`;

const NegMargin = styled(Container)((props: { theme: Theme }) => `
  margin-top:-250px;
  position:relative;
  padding-bottom:64px;
  ${props.theme.breakpoints.down("xs")} {
      padding:4px;
  }
`);

const AppBox = styled(Box)((props: { theme: Theme }) => `
  padding: 0 32px 32px 32px;
  ${props.theme.breakpoints.down("sm")} {
    padding: 0 16px 16px 16px;
  }
`);

const InnerAppBox = styled(Box)((props: { theme: Theme }) => `
  padding: 48px;

  ${props.theme.breakpoints.down("sm")} {
    padding: 0px;
  }
`);

const ShapePadding = styled(Container)((props: { theme: Theme }) => `
  ${props.theme.breakpoints.up("md")} {
    padding: 0px 64px;
  }
`);

const IndexPage: React.FC<LocationProps> = (props) => {
  const theme = useTheme<Theme>();
  const phone = useMediaQuery(theme.breakpoints.down("xs"));

  const data = useStaticQuery(graphql`
    query {
      seo: homeJson(block: {eq: "seo" }) {
        title
        description
        image {
          childImageSharp {
              resize(width: 750, height: 300, cropFocus: ENTROPY) {
                  src
                  width
                  height
              }
          }
        }
      }
      header: homeJson(block: {eq: "header"}) {
        title
        tagline
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160, maxHeight: 3120, cropFocus: ATTENTION) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      who: homeJson(block: {eq: "who"}) {
        content
        leftImage {
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 600, cropFocus: ENTROPY) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        leftTitle
        leftText
        rightImage {
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 600, cropFocus: ENTROPY) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rightTitle
        rightText
        togetherImage {
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 600, cropFocus: ENTROPY) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        togetherTitle
        togetherText
      }
      neuro: homeJson(block: {eq: "neuro"}) {
        tabs {
          content
          label
          letter
          title
          tabImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      brainInitiative: homeJson(block: {eq: "brain-initiative"}) {
        title
        content
        logoImage {
          childImageSharp {
              fixed(width: 600, height: 600, cropFocus:  ATTENTION) {
                ...GatsbyImageSharpFixed_withWebp
              }
          }
        }
      }
      neuroAcademy: homeJson(block: {eq: "neuroAcademy"}) {
        title
        content
        logoImage {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        choices {
          cta
          ctaUrl
          title
          description
          choiceImage {
            childImageSharp {
              fluid(maxWidth: 615, maxHeight: 300, cropFocus: CENTER ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      bookBlock : homeJson(block: {eq: "book"}){
        bookTeaser
        bookImage {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        preOrderImage {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        purchaseTagline
        usStores {
          link
          alt
          img {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      testimonials: homeJson(block: {eq: "testimonial" }) {
        quotes {
          quote
          author
        }
        title
        logos {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      blogPosts: allMarkdownRemark (
        sort: { order: DESC, fields: [frontmatter___publish_date] }
        filter: {
          frontmatter: {
            published: { eq: true }
          }
        }
        limit: 5
      ){
        edges {
          node {
            excerpt(pruneLength: 400)
            fields {
              slug
            }
            frontmatter {
              title
              tags
              publish_date(formatString: "MMMM D, YYYY")
              primary_image {
                  childImageSharp {
                      fluid(maxWidth: 350, maxHeight: 350, cropFocus: ENTROPY) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <MainLayout transparentNav>
      <SEO {...data.seo} location={props.location} />

      <Hero {...data.header} overlayColor="#FFFFFF" heroHeight={phone ? 55 : 85} backgroundPosition="center top" />

      {/* //Removed Event Slider section @ requests of Enrich Creative...
       <Section>
        <Container>
          <ShapePadding theme={theme} maxWidth="md">
            <ShapeArea>
              <ShapeStack zIndex={-1}>
                <ShapeDown color={theme.palette.primary.main} rotate={195} scale={.95} opacity={35} translateX={50} translateY={305} />
                <ShapeDown color={theme.palette.secondary.main} rotate={12} opacity={35} translateY={-125} translateX={-50} />
              </ShapeStack>
            </ShapeArea>
          </ShapePadding>
          <Typography variant="h3" component="h2" align="center" gutterBottom>{data.events.title}</Typography>
          <EventSlider speed={5000} events={data.events.events} blogs={data.blogPosts.edges.map(e => e.node).splice(0, data.events.blogPosts)} />
        </Container>
      </Section> */}

      <WhoBlock {...data.who} />

      <Section bgcolor={theme.palette.secondary.dark}>
        <Container maxWidth="md">
          <RoundPaper elevation={8}>
            <ContentBlock maxWidth={375} {...data.neuroAcademy} />
            {/* TODO: NEEDS JSON DATA */}
            <Box display="flex" justifyContent="center" px="auto" pb={6}>
              <LinkButton variant="contained" color="primary" size="large"
                url="https://www.theneuroplan.com/" >
                LEARN MORE
            </LinkButton>
            </Box>
          </RoundPaper>
        </Container>
      </Section>

      <Section id="book">
        <BookBlock {...data.bookBlock} />
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid item xs={12} sm={4}>
              <Box px={{xs:4,sm:0}}>
                <Img fluid={data.bookBlock.preOrderImage.childImageSharp.fluid} alt="$700 worth of Bonus Material"/>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h4">
                Pre-order The 30-Day Alzheimer’s Solution to access $700 worth of bonus materials, including the 30-Day NEURO Web Course.
            </Typography>
              <Box textAlign="center" pt={3}>
                <LinkButton url="/30-day-alzheimers-solution/#pre-order-details" variant="contained" color="primary"><Typography variant="h6">See The Bonuses</Typography></LinkButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section id="#media" bgcolor={theme.palette.primary.light}>
        <Container>
          <TestimonialSlider quotes={data.testimonials.quotes} ></TestimonialSlider>
          <LogoDiv theme={theme}>
            <LogoTitle variant="h3" align="center" gutterBottom>{data.testimonials.title}</LogoTitle>
            <Grid container alignItems="center" justify="space-around">
              {data.testimonials.logos.map((l, idx) =>
                <Grid key={idx} item xs={6} sm={3}>
                  <Box p={3}>
                    <Img fluid={l.image.childImageSharp.fluid} alt={l.alt} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </LogoDiv>
        </Container>
      </Section>

      <Section>
        <Subscribe tagline="Sign Up For Our Team Sherzai Newsletter" cta="Subscribe" />
      </Section>
      {/* <BlogTeaser /> */}
    </MainLayout >
  )
}

export default IndexPage
