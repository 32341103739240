import styled from "@emotion/styled";
import { Box, Container, Grid, Theme, Typography, useTheme } from "@material-ui/core";
import React, { ComponentType } from "react";
import Section from "../components/section";
import Img from "gatsby-image";
import { StyledMarkdown } from "../components/styledComponents";

interface WhoBlockProps {
    leftImage: {
        childImageSharp: {
            fluid: import("gatsby-image").FluidObject
        }
    };
    leftTitle: string,
    leftText: string,
    rightImage: {
        childImageSharp: {
            fluid: import("gatsby-image").FluidObject
        }
    },
    rightTitle: string,
    rightText: string,
    togetherImage: {
        childImageSharp: {
            fluid: import("gatsby-image").FluidObject
        }
    }
    togetherTitle: string,
    togetherText: string
}

const RoundImage = styled(Img)`
    clip-path: circle(50.0% at 50% 50%);
`;

const Border = styled.div`
    clip-path: circle(50.0% at 50% 50%);
    padding: 25px;
    max-width: 500px;
    margin: auto;
`;

const GradientBorder = styled(Border)((props: { theme: Theme }) => `
    background-image: linear-gradient(to right, ${props.theme.palette.primary.main}, ${props.theme.palette.secondary.main});
`);

const SolidBorder = styled(Border)((props: { color: string }) =>
    `
    background-color: ${props.color}
`);

type boxProps = import('@material-ui/core/Box').BoxProps;

const PaddedBox = styled<ComponentType<boxProps>, { theme: Theme }>(Box)(props => `
        padding: 32px;

        ${props.theme.breakpoints.down("sm")} {
            padding: 16px;
        }
    `);

const InnerContent = styled<ComponentType<boxProps>, { theme: Theme }>(Box)(props => `
        padding: 0px 16px;

        ${props.theme.breakpoints.down("sm")} {
            padding: 0px 8px;
        }
    `);


const WhoBlock: React.FC<WhoBlockProps> = (props) => {

    const theme = useTheme<Theme>();

    return (
        <Section>
            <Container maxWidth="lg">
                <Box px={{xs: 0, sm: 3}}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6}>
                            <SolidBorder color={theme.palette.primary.main}>
                                <RoundImage fluid={props.leftImage.childImageSharp.fluid} />
                            </SolidBorder>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box px={{xs:0, sm:4}} py={2} display="flex" flexDirection="column">
                                <Typography variant="h4" gutterBottom>{props.leftTitle}</Typography>
                                <StyledMarkdown source={props.leftText}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box px={{xs: 0, sm: 3}}>
                    <Grid container alignItems="center" wrap="wrap-reverse">
                        <Grid item xs={12} md={6}>
                            <Box px={{xs:0, sm:4}} py={2} display="flex" flexDirection="column">
                                <Typography variant="h4" gutterBottom>{props.rightTitle}</Typography>
                                <StyledMarkdown source={props.rightText}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SolidBorder color={theme.palette.secondary.main}>
                                <RoundImage fluid={props.rightImage.childImageSharp.fluid} />
                            </SolidBorder>
                        </Grid>
                    </Grid>
                </Box>

                <Box px={{xs: 0, sm: 3}}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6}>
                            <GradientBorder theme={theme}>
                                <RoundImage fluid={props.togetherImage.childImageSharp.fluid} />
                            </GradientBorder>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box px={{xs:0, sm:4}} py={2} display="flex" flexDirection="column">
                                <Typography variant="h4" gutterBottom>{props.togetherTitle}</Typography>
                                <StyledMarkdown source={props.togetherText}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Section>
    );
}


export default WhoBlock;